/* Center wrapper to align the container in the middle */
.center-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full viewport height */
}

.reset-password-container {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(101, 101, 101, 0.3);
    max-width: 400px;
    width: 100%;
    padding: 20px;
    text-align: center;

}

.reset-password-container h2 {
    text-align: center;
    font-weight: bolder;
    margin-bottom: 10px; /* Adjust as needed to reduce space */
}

.reset-password-container p {
    text-align: center;
    color: rgb(220, 220, 220);
    margin-top: 0;
    margin-bottom: 35px; /* Remove default top margin */
}

.p-error {
    color: rgb(255, 0, 0) !important;
    font-weight: bolder;

}

/* Form styling */
form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Reduced spacing between form elements */
}

label {
    font-weight: bold;
    text-align: left; /* Aligns label text to the left */
}

/* Input styling */
input[type="password"] {
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: transparent;
    color: white;
}

#confirmPassword {
    border-color: #bbb; /* Slightly different border color for visual cue */
}

/* Button styling */
button {
    padding: 15px;
    font-size: 16px;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 50px;
    font-weight: bold;
}

button:hover {
    background-color: #0056b3;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.spinnerStyle {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 3px solid rgba(255, 255, 255, 0.6);
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}